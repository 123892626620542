<template>
    <div>This is a pre rendered page</div>
</template>

<script lang="ts">
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';

@Component({})
export default class PreRenderedPage extends mixins(GenericPage) {

  created() {
    console.log('We are in Pre-Rendered page constructor');
  }
}
</script>